/*
 * @Author: yangecheng
 * @Date: 2021-09-30 13:05:56
 * @Description: 
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

